import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroTwo'
import NewsSection from '../components/homeNews'
import ColumnOverlay from '../components/columnOverlay'
import ColumnAlternatingColour from '../components/columnAlternatingColour'

const Index = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="usi-intro-page">
        <Hero
          class="home-110"
          foreimage={post.frontmatter.image.childImageSharp.gatsbyImageData}
          alttext={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctaslug="#scrollto"
          ctatext="Why TeamBLUE?"
          ctaslug2="/contact/"
          ctatext2="Get in touch"
          type="home"
        />
      </div>

      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="#FFFFFF"
            ></path>
          </svg>
        </div>
      </div>

      <main id="main">
        <MDBContainer className="pb-100">
          <ColumnOverlay
            cardtagtext={post.frontmatter.cardtagtext}
            cardtitle={post.frontmatter.cardtitle}
            cardtext={post.frontmatter.cardtext}
            body={post.html}
            image={post.frontmatter.cardimage.childImageSharp.gatsbyImageData}
          />
        </MDBContainer>

        {post.frontmatter.section ? (
          <section className="bg-white-grey-gradient">
            <MDBAnimation reveal type="fadeIn" duration="1587ms">
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <h2 className="font-alt font-w-700 title-xs-medium title-extra-large letter-spacing-1 text-center mt-4 mt-xl-0 mb-7">
                    {post.frontmatter.spotlighttitle}
                  </h2>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
              <MDBContainer>
                {post.frontmatter.section.map((sections, index) => {
                  return (
                    <ColumnAlternatingColour
                      key={index}
                      title={sections.title}
                      subtitle={sections.subtitle}
                      subtitlecolour={
                        sections.subtitlecolour != 'none'
                          ? sections.subtitlecolour
                          : ''
                      }
                      description={sections.description}
                      image={sections.image.childImageSharp.gatsbyImageData}
                      imageAltText={sections.alttext}
                      placement={sections.placement}
                      titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                      link={sections.btnlink}
                      colour={
                        sections.btncolour != 'none' ? sections.btncolour : null
                      }
                    />
                  )
                })}
              </MDBContainer>
            </MDBAnimation>
          </section>
        ) : (
          ''
        )}

        <NewsSection />
      </main>
    </Layout>
  )
}
export default Index 

export function Head({data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { name: { eq: "index" } }) {
      id
      html
      frontmatter {
        meta {
          title
          description 
        }        
        title
        subtitle
        cardtitle
        cardtext
        spotlighttitle
        cardtagtext
        cardtext
        cardimage {
          childImageSharp {
            gatsbyImageData(width: 100, quality: 90) 
          }
        }
        image: foreimage {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, width: 540, quality: 90) 
          }
        }
        alttext
        section {
          title
          subtitle
          subtitlecolour
          description
          image {
              childImageSharp {
                gatsbyImageData(width: 960, quality: 90) 
              }
            }
          placement
          alttext
          btnlink
          btncolour
        }
      }
    }
  }
`
