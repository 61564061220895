import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import Link from 'gatsby-link'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'

class columnAlternatingColour extends React.Component {
  render() {
    return (
      <MDBRow className={`mx-1 alt-area ${ this.props.placement === 'right' ? 'flex-row-reverse' : '' }`} >
        <MDBCol lg="6" className="alt-right grow">
          <Link to={this.props.link.charAt(0) != "/" ? "/" + this.props.link + "/" : this.props.link} aria-label={this.props.title} > 
            <GatsbyImage image={this.props.image} alt={this.props.imageAltText} className="alt-bg-image" /> 
          </Link>
        </MDBCol>

        <MDBCol lg="6" className="alt-left bg-color">
          <div className="alt-content">
            <MDBContainer>
              <MDBRow>
              {this.props.subtitle != null && (
                <MDBCol lg="12" className="px-0 order-last">
                  <h3 className={ this.props.subtitleClass != null ? this.props.subtitleClass : `font-alt font-w-700 letter-spacing-1 mb-4 title-xs-medium title-large ${this.props.subtitlecolour}` } > {this.props.subtitle} </h3>
                </MDBCol>
              )}

                <MDBCol lg="12" className="px-0 order-first">
                  <p className={ this.props.titleClass != null ? this.props.titleClass : 'font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium text-very-dark-blue' } > {/* <span id={this.props.link} className="anchor"> &nbsp; </span> */} {this.props.title} </p>
                </MDBCol>
              </MDBRow>
            </MDBContainer>

            <div className="mt-3 font-w-400 text-medium" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: this.props.description }} />

            {this.props.link ? (
              this.props.link.substring(0, 3) != 'http' ? (
                <Link to={this.props.link.charAt(0) != "/" ? "/" + this.props.link + "/" : this.props.link} className={`mt-4 ${this.props.colour}`} aria-label={this.props.title} > 
                  <FontAwesomeIcon icon={faChevronRight} /> 
                </Link>
              ) : (
                <a href={this.props.link} className={`mt-4 ${this.props.colour}`} target="_blank" rel="noopener" aria-label={this.props.title} > 
                  <FontAwesomeIcon icon={faChevronRight} /> 
                </a>
              )
            ) : null}
          </div>
        </MDBCol>
      </MDBRow>
    )
  }
}

export default columnAlternatingColour